import { STATIC_ROOT_DOMAIN } from 'common/constants/env';

const sdkScriptRegex =
  /^https:\/\/[\w-\.]+\.?\.visa\.com\/checkout\-widget\/resources\/js\/integration\/v1\/sdk\.js/;
const mobileButtonScriptRegex =
  /^https:\/\/[\w-\.]+\.?\.visa\.com\/checkout\-widget\/resources\/js\/mobile\-button\.js/;

function getSDKScriptEl() {
  const scripts = document.querySelectorAll('script');

  for (const script of scripts) {
    if (script.src.match(sdkScriptRegex)) {
      return script;
    }

    if (script.src.match(mobileButtonScriptRegex)) {
      return script;
    }
  }

  return null;
}

const assetsMap = {
  '//assets.secure.checkout.visa.com': '//secure.checkout.visa.com',
  '//cert-assets.secure.checkout.visa.com': '//cert.secure.checkout.visa.com',
  '//sandbox-assets.secure.checkout.visa.com': '//sandbox.secure.checkout.visa.com'
};

function mapAssetsUrl(url: string) {
  const assetDomains = Object.keys(assetsMap) as Array<keyof typeof assetsMap>;

  for (const assetDomain of assetDomains) {
    if (url.indexOf(assetDomain) !== -1) {
      return url.replace(assetDomain, assetsMap[assetDomain]);
    }
  }

  return url;
}

// Root domain is updated by a deploy script based on the environment. E.g.
// when deploying to production, CI replaces sandbox.secure.checkout.visa.com with
// secure.checkout.visa.com, but when deploying to cert, CI uses
// cert.secure.checkout.visa.com.
const dummyUrlHash = 'aHR0cHM6Ly9kZXYud3d3LnYubWU'; // btoa('https://sandbox.secure.checkout.visa.com')
const wasDummyUrlReplaced = STATIC_ROOT_DOMAIN !== window.atob(dummyUrlHash);

export default function getRootDomain() {
  if (wasDummyUrlReplaced) {
    return STATIC_ROOT_DOMAIN;
  }

  const sdkScriptEl = getSDKScriptEl();

  if (sdkScriptEl) {
    return mapAssetsUrl(sdkScriptEl.src.split('/', 3).join('/'));
  }

  // Default to the prod URL for any abnormal integration.
  return 'https://secure.checkout.visa.com';
}
